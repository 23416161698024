import React, {
	FC,
	useContext,
	useEffect,
	useState,
	Ref,
	forwardRef,
} from 'react';
import cx from 'classnames';
// package imports
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	ImageProps,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import {
	Link,
	LinkProps,
} from '@superhuit/starterpack-blocks/helpers/Link/render';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import { Image } from '@superhuit/starterpack-blocks/renders';
// internal imports
import { Tag, TagProps } from '@/components/atoms/Tag/render';
import block from './block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type CardEventState = 'cancelled' | 'postponed'; // TODO :: Temp maybe we won't need to extract this from CardEventProps when the data will come from the backend
export type CardEventType = {
	categories: Array<TagProps>;
	title: string;
	artist?: string;
	dates: string;
	state?: CardEventState;
	location: string;
	link?: LinkProps;
	text?: string;
	image?: ImageProps;
	publics?: Array<{ id: number; title: string; icon: ImageProps }>;
	style?: 'fill' | 'outline';
};

export type CardEventProps = CardEventType & ThemeProps & { index: number };

/**
 * COMPONENT
 */
export const CardEvent = forwardRef<Ref<any>, CardEventProps>(
	(
		{
			index,
			image,
			categories = [],
			title,
			artist,
			dates,
			location,
			link,
			text,
			state,
			theme = {},
			style = 'fill',
			publics = [],
		},
		ref
	) => {
		const { variant, blockTheme } = useThemeBlock(block.slug, theme);

		const rootClass = cx(
			'supt-cardEvent',
			getStyles(blockTheme),
			`supt-cardEvent--${style}`,
			{
				[`supt-cardEvent--${state}`]: !!state,
			}
		);

		const { __t } = useContext(LocaleContext);

		const [columnStart, setColumnStart] = useState(null);
		const [marginTop, setMarginTop] = useState(0);

		/**
		 * Set a random column start (only for desktop)
		 */
		useEffect(() => {
			const handleWindowResize = () => {
				const desktopMQ = window.matchMedia('(min-width: 1024px)');
				if (desktopMQ.matches && !columnStart) {
					const minColumnStart = index % 2 === 0 ? 1 : 7;
					let maxColumnStart = index % 2 === 0 ? 6 : 13 - 4; // 13 is on right and 4 is the minimum number of column for the card's width

					const randomColumnStart = Math.round(
						Math.random() * (maxColumnStart - minColumnStart) +
							minColumnStart
					);
					setColumnStart(randomColumnStart);
				} else if (!desktopMQ.matches && columnStart) {
					setColumnStart(null);
				}
			};

			// Trigger on 1st load
			handleWindowResize();

			window.addEventListener('resize', handleWindowResize);

			return () => {
				window.removeEventListener('resize', handleWindowResize);
			};
		}, [columnStart]);

		/**
		 * Set a random margin top
		 */
		useEffect(() => {
			const minMarginTop = 0; //-50;
			const maxMarginTop = 150;

			const randomMarginTop = Math.round(
				Math.random() * (maxMarginTop - minMarginTop) + minMarginTop
			);

			setMarginTop(randomMarginTop);
		}, []);

		return (
			<div
				className={rootClass}
				data-block={block.slug}
				data-variant={variant}
				style={{ gridColumnStart: columnStart, marginTop }}
				// @ts-ignore
				ref={ref}
			>
				<div className="supt-card__wrapper">
					{image ? (
						<Image
							{...image}
							className="supt-cardEvent__image"
							priority={true}
						/>
					) : null}
					<div className="supt-cardEvent__genres">
						{categories.map((cat, id) => (
							<Tag
								key={id}
								{...cat}
								hasBorder
								isTransparent={style === 'outline'}
							/>
						))}
					</div>

					<h4 className="supt-cardEvent__title">{title}</h4>
					{artist ? (
						<p className="supt-cardEvent__artist">{artist}</p>
					) : null}

					<div className="supt-cardEvent__infos">
						{dates ? (
							<p
								className="supt-cardEvent__date"
								dangerouslySetInnerHTML={{ __html: dates }}
							/>
						) : null}
						{state ? (
							<Tag
								title={__t(`state-${state}`, '')}
								isSquared
								type="alert"
							/>
						) : null}
						<p className="supt-cardEvent__location">{location}</p>
					</div>
					{!!publics.length && (
						<ul className="supt-cardEvent__public-list">
							{publics.slice(0, 3).map(({ id, icon, title }) => (
								<li key={id}>
									<Image {...icon} />
								</li>
							))}
							{publics.length > 3 && (
								<li className="supt-cardEvent__public-extra">
									{publics.length - 3}+
								</li>
							)}
						</ul>
					)}
					{link ? (
						<p className="supt-cardEvent__link supt-paragraph">
							<Link {...link}>
								{link.title ??
									__t('card-event-link-title', 'Découvrir')}
							</Link>
						</p>
					) : text ? (
						<p
							className="supt-cardEvent__text supt-paragraph"
							dangerouslySetInnerHTML={{ __html: text }}
						></p>
					) : null}
				</div>
			</div>
		);
	}
);

// @ts-ignore
CardEvent.slug = block.slug;
// @ts-ignore
CardEvent.title = block.title;
