import React, { FC, useState } from 'react';
import cx from 'classnames';

import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
} from '@superhuit/starterpack-blocks/utils/typings';

import { Image } from '@superhuit/starterpack-blocks/components/molecules/Image/render';
import block from '@superhuit/starterpack-blocks/components/molecules/Video/block.json';
import { getStyles } from './styles.css';
import { useVideoThumbnail } from './useVideoThumbnail';

export type VideoProps = ThemeProps & {
	id: string;
	poster: any;
	caption: string;
	source: 'youtube' | 'vimeo';
	src?: string;
	url?: string;
};

export const Video: FC<VideoProps> & BlockConfigs = ({
	id,
	poster,
	caption,
	source,
	src,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const [videoIsPlaying, setVideoIsPlaying] = useState(false);

	const rootClass = cx('supt-video', getStyles(blockTheme));

	const getVideoUrl = () => {
		if (source === 'youtube') {
			return '//www.youtube.com/embed/' + id;
		} else if (source === 'vimeo') {
			return '//player.vimeo.com/video/' + id;
		}
	};

	const thumbnail = useVideoThumbnail(source, id, poster);

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			<div className="supt-video__wrapper">
				<div className="supt-video__inner">
					<button
						onClick={() => setVideoIsPlaying(true)}
						className={cx('supt-video__play-btn', {
							'is-hidden': videoIsPlaying,
						})}
					>
						<svg
							className="supt-video__play-icon"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 48 48"
						>
							<g fill="none" fillRule="evenodd">
								<circle fill="#0271D2" cx="24" cy="24" r="24" />
								<path fill="#FFF" d="M17 14v20l19-9.913z" />
							</g>
						</svg>

						<Image {...thumbnail} className="supt-video__poster" />
					</button>

					{videoIsPlaying ? (
						src ? (
							<video
								className="supt-video__player"
								src={src}
								autoPlay
								controls
							/>
						) : (
							<iframe
								src={getVideoUrl() + '?autoplay=1'}
								frameBorder="0"
								tabIndex={-1}
								allowFullScreen={true}
								className="supt-video__player"
							/>
						)
					) : null}
				</div>
			</div>
			<p className="supt-video__caption">{caption}</p>
		</div>
	);
};

Video.slug = block.slug;
Video.title = block.title;
