import React, { FC, useContext } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { CardLinkProps } from '@superhuit/starterpack-blocks/helpers/CardControls/typings';
import {
	BlockConfigs,
	ThemeProps,
	ImageProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { Image } from '@superhuit/starterpack-blocks/renders';
import { Link } from '@/components/atoms/Link/render';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import block from './block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type CardArtistType = {
	id: number;
	image: ImageProps;
	name: string;
	role?: string;
	link?: CardLinkProps;
	showBio?: Boolean;
	desc?: string;
};
export type CardArtistProps = CardArtistType & ThemeProps;

/**
 * COMPONENT
 */
export const CardArtist: FC<CardArtistProps> & BlockConfigs = ({
	name,
	role,
	image,
	showBio = false,
	desc,
	link = {},
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const { __t } = useContext(LocaleContext);

	const rootClass = cx(
		'supt-card supt-cardArtist',
		showBio && 'supt-cardArtist--bio',
		getStyles(blockTheme),
		link?.href && 'supt-withLink'
	);

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
		>
			{image?.src && (
				<div className="supt-cardArtist__imageWrapper">
					<Image {...image} className="supt-cardArtist__image" />
				</div>
			)}

			{showBio ? (
				<div className="supt-cardArtist__content">
					<h3
						className="supt-cardArtist__name"
						dangerouslySetInnerHTML={{ __html: name }}
					></h3>
					{desc && (
						<p
							className="supt-cardArtist__bio"
							dangerouslySetInnerHTML={{ __html: desc }}
						></p>
					)}
					{link?.href ? (
						<Link
							href={link?.href}
							{...(link?.opensInNewTab && {
								target: '_blank',
								rel: 'noopener noreferrer',
							})}
							className="supt-cardArtist__link"
						>
							<span>
								{__t('card-artist-link', 'En savoir plus')}
							</span>
						</Link>
					) : null}
				</div>
			) : (
				<>
					{link?.href ? (
						<Link
							href={link?.href}
							{...(link?.opensInNewTab && {
								target: '_blank',
								rel: 'noopener noreferrer',
							})}
							className="supt-cardArtist__link"
						>
							<h3
								className="supt-cardArtist__name"
								dangerouslySetInnerHTML={{ __html: name }}
							></h3>
						</Link>
					) : (
						<h3
							className="supt-cardArtist__name"
							dangerouslySetInnerHTML={{ __html: name }}
						></h3>
					)}
					{role && (
						<p
							className="supt-cardArtist__role"
							dangerouslySetInnerHTML={{ __html: role }}
						></p>
					)}
				</>
			)}
		</div>
	);
};

CardArtist.slug = block.slug;
CardArtist.title = block.title;
