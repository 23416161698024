import React, { FC } from 'react';
import cx from 'classnames';
// package imports
import { ImageProps } from '@superhuit/starterpack-blocks/utils/typings';
import {
	ThemeProps,
	BlockConfigs,
} from '@superhuit/starterpack-blocks/utils/typings';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
// internal imports
import block from './block.json';
import { Image } from '@superhuit/starterpack-blocks/renders';
import { getStyles } from './styles.css';

export type HeaderBProps = {
	image: ImageProps;
	uptitle: string;
	title: string;
	subtitle?: string;
} & ThemeProps;

/**
 * COMPONENT
 */
export const HeaderB: FC<HeaderBProps> & BlockConfigs = ({
	image,
	uptitle,
	title,
	subtitle,
	children,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const htmlProps = {
		'data-variant': variant,
	};

	const rootClass = cx(
		'supt-headerB',
		image?.src && 'supt-headerB--hasImage',
		getStyles(blockTheme)
	);

	return (
		<header className={rootClass} data-block={block.slug} {...htmlProps}>
			<div className="supt-section__inner">
				{image?.src ? (
					<div className="supt-section__image">
						<Image {...image} layout="fill" />
					</div>
				) : null}
				<div className="supt-section__headline">
					{uptitle && (
						<h2
							className="supt-section__uptitle"
							dangerouslySetInnerHTML={{
								__html: uptitle,
							}}
						/>
					)}
					{title && (
						<h1
							className="supt-section__title"
							dangerouslySetInnerHTML={{
								__html: title,
							}}
						/>
					)}
					{subtitle && (
						<h3
							className="supt-section__subtitle"
							dangerouslySetInnerHTML={{
								__html: subtitle,
							}}
						/>
					)}
				</div>
				<div className="supt-section__content">{children}</div>
			</div>
		</header>
	);
};

HeaderB.slug = block.slug;
HeaderB.title = block.title;
