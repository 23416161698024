import React, { FC } from 'react';
import cx from 'classnames';
// package imports
import { ImageProps } from '@superhuit/starterpack-blocks/utils/typings';
import {
	ThemeProps,
	BlockConfigs,
} from '@superhuit/starterpack-blocks/utils/typings';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { Image } from '@superhuit/starterpack-blocks/renders';
import { ButtonAttributes } from '@superhuit/starterpack-blocks/helpers/Button/typings';
import { SectionProvider } from '@superhuit/starterpack-context';
// internal imports
import { ButtonSecondary } from '@/components/atoms/renders';
import block from './block.json';
import { getStyles } from './styles.css';

export type HeaderCProps = {
	image: ImageProps;
	uptitle: string;
	title: string;
	subtitle?: string;
	cta?: ButtonAttributes;
} & ThemeProps;

/**
 * COMPONENT
 */
export const HeaderC: FC<HeaderCProps> & BlockConfigs = ({
	image,
	uptitle,
	title,
	subtitle,
	cta,
	children,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const htmlProps = {
		'data-variant': variant,
	};

	const rootClass = cx('supt-headerC', getStyles(blockTheme));

	return (
		<header className={rootClass} data-block={block.slug} {...htmlProps}>
			<div className="supt-section__inner">
				<div className="supt-section__top">
					<div className="supt-section__topInner">
						<div className="supt-section__headline">
							{uptitle && (
								<h2
									className="supt-section__uptitle"
									dangerouslySetInnerHTML={{
										__html: uptitle,
									}}
								/>
							)}
							{title && (
								<h1
									className="supt-section__title"
									dangerouslySetInnerHTML={{
										__html: title,
									}}
								/>
							)}
							{subtitle && (
								<h3
									className="supt-section__subtitle"
									dangerouslySetInnerHTML={{
										__html: subtitle,
									}}
								/>
							)}
							{cta && (
								<SectionProvider variant="dark">
									<ButtonSecondary {...cta} />
								</SectionProvider>
							)}
						</div>
						<div className="supt-section__image">
							<Image {...image} />
						</div>
					</div>
				</div>
				<div className="supt-section__contentWrapper">
					<div className="supt-section__content">{children}</div>
				</div>
			</div>
		</header>
	);
};

HeaderC.slug = block.slug;
HeaderC.title = block.title;
