import React, { forwardRef, Ref, Fragment, useState, useCallback } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import { Section } from '@superhuit/starterpack-blocks/helpers/Section';
import {
	ImageProps,
	SectionOneColumnProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { LinkProps } from '@superhuit/starterpack-blocks/helpers/Link/render';
import { ButtonSecondary } from '@/components/atoms/renders';
import { Image } from '@superhuit/starterpack-blocks/renders';
// styles
import {
	ListHighlightedProductions,
	CardProductionType,
} from '@/components/renders';
import { getStyles as getSectionStyles } from '@superhuit/starterpack-blocks/components/organisms/Sections/SectionOneColumn/styles.css';
import { getStyles } from './styles.css';
import block from './block.json';

import { HighProdProvider } from '@/contexts/high-prod-context';

/**
 * TYPINGS
 */
type ProductionProps = CardProductionType & {
	background?: Pick<ImageProps, 'src' | 'alt' | 'width' | 'height'> & {
		id: number;
		type: 'image' | 'video';
	};
};
type SectionHighlightedProductionsProps = SectionOneColumnProps & {
	link?: LinkProps;
	productions: Array<ProductionProps>;
};

/**
 * COMPONENT
 */
export const SectionHighlightedProductions = forwardRef<
	Ref<any>,
	SectionHighlightedProductionsProps
>(
	(
		{
			anchor,
			className,
			title,
			link,
			productions,
			slug,
			variant: currentVariant = 'dark',
			titleTag = 'h2',
			theme = {},
			getStyles: getDfltStyles = getSectionStyles,
		},
		ref
	) => {
		const { variant, blockTheme } = useThemeBlock(
			slug,
			theme,
			currentVariant
		);

		const trimmedSlug = slug.split('/');
		const modifierSlug = trimmedSlug[trimmedSlug.length - 1];

		const [activeId, setActiveId] = useState(-1);

		const rootClass = cx(
			'supt-section supt-section--oneCol',
			`supt-section--${modifierSlug}`,
			'supt-section--colored',
			getDfltStyles(blockTheme),
			getStyles(blockTheme),
			className
		);

		const Background = useCallback(
			({ bg, productionId }) => {
				if (!bg) return null;

				if (bg.type === 'image') {
					return (
						<Image
							{...bg}
							layout="fill"
							className={cx(
								'supt-section__background__media',
								'supt-section__background__media--image',
								{
									'supt-section__background__media--active':
										activeId === productionId,
								}
							)}
						/>
					);
				} else if (bg.type === 'video') {
					return (
						<video
							className={cx(
								'supt-section__background__media',
								'supt-section__background__media--video',
								{
									'supt-section__background__media--active':
										activeId === productionId,
								}
							)}
							src={bg.src as string}
							autoPlay
							loop
							muted
							playsInline
						/>
					);
				}

				return null;
			},
			[activeId]
		);

		return productions?.length ? (
			<Section
				id={anchor}
				className={rootClass}
				variant={variant}
				slug={slug}
				ref={ref}
			>
				<HighProdProvider activeId={activeId} setActiveId={setActiveId}>
					<div className={cx('supt-section__background')}>
						{productions?.map(
							({ background, ...production }, index) => (
								<Background
									key={index}
									bg={background}
									productionId={production.id}
								/>
							)
						)}
					</div>
					<div className="supt-section__inner">
						{(title || link?.href) && (
							<div className="supt-section__headline">
								{title && (
									<Section.Title
										text={title}
										titleTag={titleTag}
									/>
								)}

								{link?.href ? (
									<div className="supt-section__linkWrapper">
										<ButtonSecondary {...link} />
									</div>
								) : null}
							</div>
						)}
						<div className="supt-section__content">
							{/* @ts-ignore */}
							<ListHighlightedProductions
								/* @ts-ignore */
								productions={productions}
							/>
						</div>
					</div>
				</HighProdProvider>
			</Section>
		) : null;
	}
);

// @ts-ignore
SectionHighlightedProductions.slug = block.slug;
// @ts-ignore
SectionHighlightedProductions.title = block.title;
