import React, { FC, useRef, useEffect } from 'react';
import cx from 'classnames';
// package imports
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ThemeProps,
	ImageProps,
	VideoProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { Image } from '@superhuit/starterpack-blocks/components/molecules/Image/render';
import { Video } from '../Video/render';
// internal imports
import block from '@superhuit/starterpack-blocks/components/molecules/Media/block.json';
// styles
import { getStyles } from '@superhuit/starterpack-blocks/components/molecules/Media/styles.css';

/**
 * TYPINGS
 */
type MediaProps = {
	mediaType?: 'image' | 'video';
	image?: ImageProps;
	motionImages?: Array<ImageProps>;
	video?: VideoProps;
	isSticky?: boolean;
} & ThemeProps;

/**
 * COMPONENT
 */
export const Media: FC<MediaProps> & BlockConfigs = ({
	mediaType = 'image',
	image,
	motionImages = [],
	video,
	isSticky,
	theme = {},
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	const rootClass = cx('supt-media', getStyles(blockTheme), {
		'is-sticky': isSticky,
	});

	const elementRef = useRef<HTMLDivElement>(null);
	const imageRef = useRef<HTMLElement>(null);
	const motionImagesRef = useRef([]);

	const threshold = useRef([]);
	const elementSize = useRef({
		triggerTop: 0,
		triggerBottom: 0,
	});
	const currentImage = useRef({ id: 0, value: 0 });

	/**
	 * Init for stop motion images
	 * Set threshold + triggers positions + scroll event listener
	 */
	useEffect(() => {
		if (!motionImages.length) return;

		if (window.matchMedia('(max-width: 767px)').matches) return; // Enable images stop-motion only on desktop and tablet

		// Create THRESHOLD dynamically according to number of stop-motion images
		const coef = 1 / (motionImages.length + 1); // + 1 because motionImages are added to default image

		// Reset thresholds (else we will have surprises when this component re-renders)
		threshold.current = [];

		let i = 0;
		while (i < 1) {
			threshold.current.push(Math.round(i * 100) / 100); // Round number to 2 decimals
			i += coef;
		}

		getTriggerSizes();

		window.addEventListener('scroll', handleScroll);
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleScroll = (_event) => {
		// Update images on scroll only if image is fully visible
		if (
			document.documentElement.scrollTop >=
				elementSize.current.triggerTop &&
			document.documentElement.scrollTop <=
				elementSize.current.triggerBottom
		) {
			const currentRatio =
				(document.documentElement.scrollTop -
					elementSize.current.triggerTop) /
				(elementSize.current.triggerBottom -
					elementSize.current.triggerTop);

			const currentThreshold = getCurrentIndex(
				threshold.current,
				currentRatio
			);

			if (currentThreshold.id !== currentImage.current.id) {
				displayImage(currentThreshold.id);
				currentImage.current = currentThreshold;
			}
		}
	};

	const handleResize = () => {
		getTriggerSizes();
	};

	/**
	 * Get trigger sizes for the images stop-motion
	 */
	const getTriggerSizes = () => {
		const imageHeight = imageRef.current.offsetHeight;
		const parent = elementRef.current.closest('.supt-section');
		const size = parent.getBoundingClientRect();

		elementSize.current = {
			// element top - window height + image height => when image is 100% visible
			triggerTop:
				size.top +
				document.documentElement.scrollTop -
				window.innerHeight +
				imageHeight,

			// element top - element height - image height => last time image is 100% visible
			triggerBottom:
				size.top +
				document.documentElement.scrollTop +
				size.height -
				imageHeight,
		};
	};

	const getCurrentIndex = (array: number[], number: number) => {
		// First index that number is bigger than
		return array.reduce(
			(prev, curr, index) => {
				return number >= curr ? { id: index, value: curr } : prev;
			},
			{ id: 0, value: 0 }
		);
	};

	/**
	 * Display image for specific index
	 */
	const displayImage = (index: number) => {
		motionImagesRef.current.forEach((image, i) => {
			if (!image?.style) return;
			image.style.opacity = i >= index ? 1 : 0;
		});
	};

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
			ref={elementRef}
		>
			{mediaType === 'image' ? (
				image?.src &&
				(motionImages.length ? (
					<span
						className="supt-media__motionImagesWrapper"
						ref={imageRef}
					>
						<Image
							{...image}
							alt={image.alt || ''}
							className="supt-media__image"
							ref={(ref) => (motionImagesRef.current[0] = ref)}
							style={{ zIndex: motionImages.length }}
						/>
						{motionImages.map((motionImage, index) => (
							<Image
								key={index}
								{...motionImage}
								caption={image.caption}
								alt={motionImage.alt || ''}
								className="supt-media__image"
								ref={(ref) =>
									(motionImagesRef.current[index + 1] = ref)
								}
								style={{
									zIndex: motionImages.length - (index + 1),
								}}
							/>
						))}
					</span>
				) : (
					<Image
						{...image}
						alt={image.alt || ''}
						className="supt-media__image"
					/>
				))
			) : (
				<Video {...video} />
			)}
		</div>
	);
};

Media.slug = block.slug;
Media.title = block.title;
