import { css } from '@emotion/css';
import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
import { transitions } from '@superhuit/starterpack-blocks/css/resources/variables.css';
import {
	expandLink,
	fluidValues,
	fontStyle,
} from '@superhuit/starterpack-blocks/css/resources/mixins.css';
import {
	imageExpandTransition,
	imageReduceTransition,
	linkNoUnderlineWithHover,
	linkUnderlineWithHoverRedraw,
} from '@/css/placeholders.css';

const getDynamicStyles = (styles) =>
	cssObj({
		'.supt-cardShop__title': fontStyle(
			{
				...styles?.title?.typography,
				textAlign: styles?.title?.textAlignment,
				color: styles?.title?.color,
			},
			false
		),

		'.supt-cardShop__content': fontStyle(
			{
				...styles?.content?.typography,
				textAlign: styles?.content?.textAlignment,
				color: styles?.content?.color,
			},
			false
		),

		'.supt-cardShop__link': fontStyle(
			{
				...styles?.link?.typography,
				textAlign: styles?.link?.textAlignment,
				color: styles?.link?.color,
			},
			false
		),
	});

const getStyles = (styles) =>
	css(getDynamicStyles(styles), {
		overflow: 'hidden',
		maxWidth: '100%',
		display: 'inline-flex',
		flexDirection: 'column',
		position: 'relative',

		'.supt-cardShop__imageWrapper': cssObj(
			fluidValues('marginBottom', 15, 20),
			{
				display: 'flex',
				position: 'relative',
				overflow: 'hidden',
			}
		),

		'.supt-cardShop__titleWrapper': {
			display: 'block',
		},

		'.supt-cardShop__image': {
			display: 'inline-flex',
		},

		'.supt-cardShop__content': fluidValues('marginTop', 8, 12),

		'.supt-cardShop__linkWrapper': {
			marginTop: 'auto',
		},

		'.supt-cardShop__link': cssObj(fluidValues('marginTop', 12, 16), {
			textDecoration: 'none',
			display: 'inline-flex',

			span: cssObj(linkUnderlineWithHoverRedraw, {
				zIndex: 2,
			}),
		}),

		/* with link */
		'&.supt-withLink': cssObj(expandLink(), {
			transition: `all ${transitions.mid} ease-out`,

			'.supt-cardShop__image': cssObj(imageReduceTransition),

			'.supt-cardShop__title': cssObj(linkNoUnderlineWithHover, {
				backgroundPosition: '0 0.85em',
			}),

			'&:hover, &:focus-within': cssObj(
				getDynamicStyles(styles?.states?.hover),
				{
					'.supt-cardShop__image': cssObj(imageExpandTransition),
					'.supt-cardShop__title': {
						backgroundSize: '100% 1px',
					},
				}
			),
		}),
	});

// edit
const getEditGlobalStyles = (styles) =>
	cssObj({
		'.wp-block[data-type="supt/card-shop"]': {
			'.supt-card': {
				width: '100%',

				'.supt-cardShop__image': {
					width: '100%',
					'.supt-image-control': {
						width: '100%',
					},
				},

				'.supt-cardShop__title': {
					margin: 0,
				},
			},
		},
	});

export { getStyles, getEditGlobalStyles };
