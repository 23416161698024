import React, { FC, useEffect, useRef } from 'react';
import cx from 'classnames';
import { useThemeBlock } from '@superhuit/starterpack-hooks';
import {
	BlockConfigs,
	ChildrenProps,
	ImageProps,
} from '@superhuit/starterpack-blocks/utils/typings';
import { ThemeProps } from '@superhuit/starterpack-blocks/utils/typings';
import { Image } from '@superhuit/starterpack-blocks/renders';
import block from '@superhuit/starterpack-blocks/components/molecules/cards/CardCta/block.json';
// styles
import { getStyles } from './styles.css';

/**
 * TYPINGS
 */
export type CardCtaProps = {
	title: string;
	text?: string;
	image?: ImageProps;
	isSmall?: boolean;
	backgroundColor?: 'grey' | 'black' | 'pink';
	className?: string;
} & ThemeProps &
	ChildrenProps;

/**
 * COMPONENT
 */
export const CardCta: FC<CardCtaProps> & BlockConfigs = ({
	title,
	text,
	image,
	isSmall,
	backgroundColor = 'grey', // TODO: to deprecate
	theme = {},
	children,
	className,
}) => {
	const { variant, blockTheme } = useThemeBlock(block.slug, theme);

	if (backgroundColor && backgroundColor !== 'grey' && !className) {
		className = `is-style-${backgroundColor}`;
	}

	const rootClass = cx(
		'supt-card supt-cardCta',
		// @ts-ignore
		children?.props?.blocks[0].innerBlocks.length === 1 &&
			'supt-cardCta--oneButton',
		getStyles(blockTheme),
		{
			hasImage: image?.src,
			isSmall,
		},
		className
	);

	const elRef = useRef(null);

	useEffect(() => {
		if (isSmall && image?.src) {
			elRef.current.parentElement.classList.add('hasImage');
		}
	}, []);

	return (
		<div
			className={rootClass}
			data-block={block.slug}
			data-variant={variant}
			ref={elRef}
		>
			{image?.src ? (
				<div className="supt-cardCta__image">
					<Image {...image} />
				</div>
			) : null}

			<div className="supt-cardCta__inner">
				<div className="supt-cardCta__content">
					<h3
						className="supt-cardCta__title"
						dangerouslySetInnerHTML={{ __html: title }}
					></h3>

					{text && (
						<p
							className="supt-cardCta__text"
							dangerouslySetInnerHTML={{ __html: text }}
						></p>
					)}
				</div>
				<div className="supt-cardCta__buttons">{children}</div>
			</div>
		</div>
	);
};

CardCta.slug = block.slug;
CardCta.title = block.title;
